.header {
    width: 100%;
    height: 60px;
    background-color: #492E87;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: sticky;
    z-index: 3;
    top: 0;
}

.duimage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.headbutton {
    text-decoration: none;
    border: none;
    border-radius: 8px;
    color: white;
    background-color: transparent;
    font-weight: 600;
    padding: 4px;
    margin: 0 10px;
}

.headbutton:hover {
    text-decoration: underline;
}

.active {
    background-color: #fff;
    color: #000;
}

.active:hover {
    text-decoration: none;
}

.three-dots {
    display: none;
}

.nav-links {
    display: flex;
    align-items: center;
}

.mobile-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: rgba(73, 46, 135,0.8);
    position: absolute;
    top: 60px;
    width: 100%;
    padding: 10px 0;
    z-index: 10;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .three-dots {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }

    .mobile-menu {
        display: flex;
    }
}
