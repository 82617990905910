.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
}
.gallery .images {
    gap: 15px;
    max-width: 95%;
    margin-top: 40px;
    columns: 4;
    list-style-type: none;
    padding: 0;
}
@media(max-width:450px){
    .gallery .images {
        columns: 2;
    }
}
.card {
    width: 100%;
}
.container {
    border-radius: 8px;
    margin: 15px;
    position: relative;
    width: 100%;
    padding: 0 !important;
}
.photo {
    display: block;
    width: 100%;
    height: auto;
}
.overlay {
    border-radius: 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
}
.container:hover .overlay {
    width: 100%;
}
.text {
    white-space: nowrap;
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.loading {
    font-size: 1.5rem;
    color: #4C3BCF;
    text-align: center;
    margin-top: 2rem;
  }
.loader {
    --c1:#673b14;
    --c2:#f8b13b;
    width: 40px;
    height: 80px;
    border-top: 4px solid var(--c1);
    border-bottom: 4px solid var(--c1);
    background: linear-gradient(90deg, var(--c1) 2px, var(--c2) 0 5px,var(--c1) 0) 50%/7px 8px no-repeat;
    display: grid;
    overflow: hidden;
    animation: l5-0 2s infinite linear;
  }
  .loader::before,
  .loader::after {
    content: "";
    grid-area: 1/1;
    width: 75%;
    height: calc(50% - 4px);
    margin: 0 auto;
    border: 2px solid var(--c1);
    border-top: 0;
    box-sizing: content-box;
    border-radius: 0 0 40% 40%;
    -webkit-mask: 
      linear-gradient(#000 0 0) bottom/4px 2px no-repeat,
      linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
            mask-composite: exclude;
    background: 
      linear-gradient(var(--d,0deg),var(--c2) 50%,#0000 0) bottom /100% 205%,
      linear-gradient(var(--c2) 0 0) center/0 100%;
    background-repeat: no-repeat;
    animation: inherit;
    animation-name: l5-1;
  }
  .loader::after {
    transform-origin: 50% calc(100% + 2px);
    transform: scaleY(-1);
    --s:3px;
    --d:180deg;
  }
  @keyframes l5-0 {
    80%  {transform: rotate(0)}
    100% {transform: rotate(0.5turn)}
  }
  @keyframes l5-1 {
    10%,70%  {background-size:100% 205%,var(--s,0) 100%}
    70%,100% {background-position: top,center}
  }
  