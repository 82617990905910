.about{
    display: flex;
    gap: 50px;
    align-items: center;
}
.img-shiv{
    width: 20rem;
    height: 25rem;
    margin: 60px;
}
.afooter{
    display:flex;
    justify-content: space-around;
    height: fit-content;
    background-color: #758694;
    color: #F7E7DC;
}
.sinc{
    line-height: 0.2;
    
}
.qr{
    width: 10rem;
    height: 10rem;
}
@media(max-width:450px){
    .img-shiv{
        margin: 0;
    }
    .about{
        flex-direction: column;
        gap: 20px;
    }
    .afooter{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}