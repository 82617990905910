.home-main{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
}
.home-main img{
    height: 75%;
    width: 25%;
}
@media(max-width:450px){
    .home-main{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-main img{
        width: 100%;
        height: auto;
    }
}
.home-img{
    width: 300px !important;
    height:400px !important;
    transform: scaleX(-1);
}