.newsmain{
    position:absolute;
    right: 0;
    top: 60px;
    width: 20%;
    height: fit-content;
    background-color: rgba(255, 242, 215,0.8);
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 8px;
    padding: 30px;
}
@media(max-width:450px){
    .newsmain{
        position:static;
        width: 100%;
        margin: 0;
        width: 100%;
    }
}
.news-head{
    text-align: center;
    color: #803D3B;
}
.news-links{
    font-size: large;
    font-weight:500;
}
.news-discript{
    cursor: pointer;
}
.news-discript:hover{
    text-decoration: underline;
}