.backscreen {
    width: 100%;
    filter: opacity(40%);
    background-color: black;
    position: fixed;
    top: 0%;
    left: 0%;
    height: 2000px;
    z-index: 5;
}

.main-up {
    background-color: #fff;
    padding: 20px;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 300px;
    height: fit-content;
    border-radius: 5px;
    z-index: 6;
}