
.black-outside{
    display: flex;
    flex-direction: column;
    width: 79%;
    align-items: center;
}
.text-back{
    background-image: linear-gradient(to bottom right, #071952,#4C3BCF );
    color: #fff;
    height:60px;
    display: flex;
}
.idcard{
    margin: 10rem;
    line-height: 0.8;
    color: #000 !important;
    width: 32rem;
    height: 18rem;
    max-width: 100%;
    max-height: 80%;
}
.idholder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 80%;
    line-height: 0.5;
}
.idimage{
    width: 200px;
    height: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 50%;
}
.sign-box{
    flex: end !important;
    justify-content: flex-end;
    line-height: 10% !important;
    height: fit-content;
    margin-left: 20%;
    font-size:9px;
}
.sign-box img{
    width: 150px !important;
    margin-left: 30%;
    margin-right: 10%;
    height: 70px;
    font-size: smaller !important;
}
.div-some{
    margin-left: 50px;
    line-height: 60%; 
}
.logo-id{
    width: 10%;
    height: auto;
    margin: 7px;
    border-radius: 50%;
}
.main-id-fin{
    margin: 0;
    line-height: 0.2;
    margin-top: 20px;
}