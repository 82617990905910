.App {
  text-align: center;
}
*{
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mainpage{
  width: 79%;
}
@media(max-width:450px){
  .mainpage{
     width: 100%;
     padding: 10px;
   }
  .maindivs{
    width: 80% !important;
  }
  .pdf-div{
    width: 100% !important;
  }
}
.prof{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}
.pdf-div{
  width: 79%;
  padding: 50px;
  background-color: #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.maindivs{
  width: 50% !important;
}
.pdf-img{
  width: 100%;
  height: auto;
}
.justify{
  text-align: justify;
  text-justify: inter-word;
}
.modal-backdrop-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040; /* Make sure it's behind the modal */
}

.modal-custom {
  z-index: 1050; /* Make sure it's in front of the backdrop */
}
